import { Flex, Box, SystemProps } from "@storyofams/react-ui";
import { Button, Heading, Text } from "../../../../components";
import { getLinkProps } from "../../../../lib";

type TitleComboProps = {
  content: {
    title: string;
    description?: string;
    link_label?: string;
    link_url?: any;
  };
  first?: boolean;
} & SystemProps;

export const SeeMoreButton = ({ link, variant = "", children, ...props }) => (
  <Button
    variant="link-arrow"
    href={getLinkProps(link)}
    borderBottom={"1px solid black"}
    borderRadius={"none"}
    fontWeight={500}
    fontSize={"18px"}
    whiteSpace="nowrap"
    display={variant === "mobile" ? ["flex", "none"] : "flex"}
    mt={variant === "mobile" && 3}
    ml={variant === "mobile" && "auto"}
    width={variant === "mobile" && "max-content"}
    {...props}
  >
    {children}
  </Button>
);

export const TitleCombo = ({ content, first, ...props }: TitleComboProps) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="flex-end"
      mb={[2, 3, 3]}
      {...props}
    >
      <Box>
        <Text fontFamily={"DINPro"} fontSize={"36px"} fontWeight={700} lineHeight={"43.2px"}>
          {content?.title}
        </Text>

        {content?.description && (
          <Text
            variant="lg"
            color="#1D1D1BCC"
            mt={[1.5, 1]}
            mr={1}
            fontSize={[2, 2]}
          >
            {content?.description}
          </Text>
        )}
      </Box>

      {content?.link_label &&
        getLinkProps(content?.link_url) &&
        (props.border ? (
          <Box border="1px solid black" borderRadius="md">
            <SeeMoreButton
              link={content?.link_url}
              pt={"16px"}
              pb={"14px"}
              px={"40px"}
            >
              {content?.link_label}
            </SeeMoreButton>
          </Box>
        ) : (
          <SeeMoreButton link={content?.link_url}>
            {content?.link_label}
          </SeeMoreButton>
        ))}
    </Flex>
  );
};
